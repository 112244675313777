import { j as e } from "./jsx-runtime-B6kdoens.js";
import "./Accordion-C-bYsxc8.js";
import "./Avatar-95vFNu6x.js";
import "./Badge-C0bj4DFU.js";
import { B as N } from "./Button-ChOUYHf0.js";
import { D as g } from "./DropdownMenu-DpOOYGFZ.js";
import { I as m } from "./Icon-BmXCLOWx.js";
import "./Input-1P9Hc4eP.js";
import "./Label-QGUB-Cyk.js";
import { T as b } from "./Text-DyXxCJgw.js";
import "./Tooltip-Dy7rqQaX.js";
import { c as i, g as w } from "./utils-CJ9afRe1.js";
import { S as _ } from "./Stack-C1p7gIIp.js";
import "./Switch-DM4pM7wx.js";
import "./Toast-DdHjWWF8.js";
import * as x from "react";
import $, { useState as T, useCallback as A } from "react";
import { T as C } from "./Toggle-Cn0qt44_.js";
import "./Breadcrumbs.component-DKHFWiDx.js";
import "./ClientFolder-C4vWR7ES.js";
import "./DocumentCard-D_iEGx36.js";
import { c as L } from "./index-BV2fynjD.js";
const k = ({ children: s, className: a }) => /* @__PURE__ */ e.jsx(
  "span",
  {
    className: i(
      "border-l border-gray-neutral-60 text-black-primary text-xs first:border-0 px-3 truncate",
      a
    ),
    children: s
  }
);
k.displayName = "LoanSummaryHeaderItem";
const P = ({
  className: s,
  children: a
}) => {
  const r = w(a, "LoanSummaryHeaderItem") || [];
  return /* @__PURE__ */ e.jsx(
    "div",
    {
      className: i(
        "flex items-center bg-black-10 rounded-md border border-gray-neutral-20 py-2 w-fit",
        s
      ),
      children: r
    }
  );
}, fe = Object.assign(P, { Item: k }), v = x.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx(
  "tbody",
  {
    ref: r,
    className: i("[&_tr:last-child]:border-0", s),
    ...a
  }
));
v.displayName = "TableBody";
const z = x.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx(
  "caption",
  {
    ref: r,
    className: i("mt-4 text-sm text-muted-foreground", s),
    ...a
  }
));
z.displayName = "TableCaption";
const R = x.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx(
  "td",
  {
    ref: r,
    className: i("p-4 align-middle [&:has([role=checkbox])]:pr-0", s),
    ...a
  }
));
R.displayName = "TableCell";
const H = x.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx(
  "tfoot",
  {
    ref: r,
    className: i("bg-primary font-medium text-primary-foreground", s),
    ...a
  }
));
H.displayName = "TableFooter";
const S = x.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx(
  "th",
  {
    ref: r,
    className: i(
      "px-4 py-4 text-left align-middle font-medium text-muted-foreground [&:has([role=checkbox])]:pr-0 text-xs text-black-primary",
      s
    ),
    ...a
  }
));
S.displayName = "TableHead";
const W = $.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx(
  "thead",
  {
    ref: r,
    className: i(
      "[&_tr]:border-b bg-gray-table-header min-h-[48px]",
      s
    ),
    ...a
  }
));
W.displayName = "TableHeader";
const O = x.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx(
  "tr",
  {
    ref: r,
    className: i(
      "border-b transition-colors hover:bg-muted/50 data-[state=selected]:bg-muted",
      s
    ),
    ...a
  }
));
O.displayName = "TableRow";
const I = x.forwardRef(({ className: s, ...a }, r) => /* @__PURE__ */ e.jsx("div", { className: "w-full overflow-auto", children: /* @__PURE__ */ e.jsx(
  "table",
  {
    ref: r,
    className: i("w-full caption-bottom text-sm", s),
    ...a
  }
) }));
I.displayName = "Table";
const f = Object.assign(I, {
  Header: W,
  Body: v,
  Footer: H,
  Row: O,
  Head: S,
  Cell: R,
  Caption: z
}), F = L("border rounded-md", {
  variants: {
    size: {
      md: "h-10",
      sm: "h-8",
      xmd: "h-9",
      lg: "h-11"
    }
  },
  defaultVariants: {
    size: "md"
  }
}), M = ({
  children: s,
  size: a,
  className: r = ""
}) => {
  const d = x.Children.map(s, (t, o) => {
    if (x.isValidElement(t)) {
      let c = o === 0 ? "rounded-r-none" : "rounded-none border-l";
      return c = x.Children.count(s) === o + 1 ? "rounded-l-none border-l" : c, x.cloneElement(t, {
        size: a,
        className: `${c} h-full`
      });
    }
    return t;
  });
  return /* @__PURE__ */ e.jsx(_, { row: !0, className: i(F({ size: a, className: r })), children: d });
}, U = C;
U.displayName = "Toggle";
M.Toggle = U;
const D = ({ className: s, children: a }) => /* @__PURE__ */ e.jsx(
  b,
  {
    className: i(
      "text-gray-neutral-70 text-sm mt-1 leading-normal",
      s
    ),
    children: a
  }
);
D.displayName = "UserTypeCardDescription";
const E = ({ className: s, children: a }) => /* @__PURE__ */ e.jsx(
  b,
  {
    className: i(
      "text-black-primary text-md font-medium leading-normal",
      s
    ),
    children: a
  }
);
E.displayName = "UserTypeCardTitle";
const Y = ({
  className: s,
  children: a,
  type: r = "lender",
  selected: d
}) => {
  const t = w(a, "UserTypeCardTitle"), o = w(
    a,
    "UserTypeCardDescription"
  );
  return /* @__PURE__ */ e.jsxs(
    "div",
    {
      className: i(
        "flex px-4 py-5 bg-white-neutral border border-gray-neutral-80 max-w-[480px] w-[480px] rounded-md",
        s,
        { "bg-blue-10": d }
      ),
      children: [
        r === "lender" ? /* @__PURE__ */ e.jsx(m, { name: "Lender" }) : /* @__PURE__ */ e.jsx(m, { name: "Borrower" }),
        /* @__PURE__ */ e.jsxs("div", { className: "ml-4 max-w-[300px]", children: [
          t,
          o
        ] }),
        d && /* @__PURE__ */ e.jsx(
          m,
          {
            name: "Check",
            strokeWidth: 1.5,
            width: 28,
            height: 28,
            className: "bg-blue-100 text-white-neutral rounded-full m-auto mr-0"
          }
        )
      ]
    }
  );
}, je = Object.assign(Y, {
  Title: E,
  Description: D
}), G = ({
  children: s,
  className: a,
  headersLength: r = 10,
  allHeaders: d,
  selectedHeaders: t,
  fixedHeaders: o = [],
  toggleHeader: c,
  onReset: j
}) => {
  const [p, u] = T(!1), [n, h] = T(""), B = (l) => {
    t.length < r ? c(l) : h(
      `You can only add up to ${r} field${r > 1 ? "s" : ""} to Table View`
    );
  }, V = (l) => {
    c(l), h("");
  };
  return /* @__PURE__ */ e.jsxs(
    g,
    {
      modal: !1,
      open: p,
      onOpenChange: (l) => {
        u(l), h("");
      },
      children: [
        /* @__PURE__ */ e.jsx(g.Trigger, { className: i(a), asChild: !0, children: s ?? /* @__PURE__ */ e.jsxs(C, { size: "sm", pressed: p, children: [
          /* @__PURE__ */ e.jsx(m, { name: "GridAdd", width: 16, strokeWidth: 1.5 }),
          /* @__PURE__ */ e.jsx(b, { size: "sm", className: "ml-1", children: "Customize Table" })
        ] }) }),
        /* @__PURE__ */ e.jsxs(
          g.Content,
          {
            withPortal: !0,
            className: "border border-gray-neutral-20 rounded-md w-fit sm:w-[629px]",
            children: [
              /* @__PURE__ */ e.jsxs("div", { className: "py-2 px-6 pr-3 flex justify-between items-center border-b border-gray-neutral-80", children: [
                /* @__PURE__ */ e.jsx(b, { size: "sm", children: "Customize Table Columns" }),
                /* @__PURE__ */ e.jsx(
                  N,
                  {
                    variant: "secondary",
                    className: "rounded-full p-1 h-auto bg-blue-20",
                    onClick: () => u(!1),
                    children: /* @__PURE__ */ e.jsx(m, { name: "Cancel", width: 24, strokeWidth: 1.5 })
                  }
                )
              ] }),
              /* @__PURE__ */ e.jsxs("div", { className: "px-6 py-5 max-h-[550px] overflow-auto", children: [
                !!n && /* @__PURE__ */ e.jsxs(
                  b,
                  {
                    variant: "destructive",
                    size: "sm",
                    className: "mb-4 flex items-center",
                    children: [
                      /* @__PURE__ */ e.jsx(m, { name: "InfoEmpty", width: 20, className: "mr-2" }),
                      n
                    ]
                  }
                ),
                /* @__PURE__ */ e.jsxs("div", { className: "flex justify-between", children: [
                  /* @__PURE__ */ e.jsxs("div", { className: "rounded-md border border-gray-neutral-80 p-3 w-[45%]", children: [
                    /* @__PURE__ */ e.jsxs("div", { className: "flex items-center border-b border-gray-neutral-80 py-2", children: [
                      /* @__PURE__ */ e.jsx(
                        m,
                        {
                          name: "InfoEmpty",
                          width: 20,
                          strokeWidth: 1.5,
                          className: "text-blue-100"
                        }
                      ),
                      /* @__PURE__ */ e.jsx(b, { size: "sm", className: "ml-1", children: "Info View" })
                    ] }),
                    /* @__PURE__ */ e.jsx("ul", { "data-testid": "info-view", children: d.filter(
                      (l) => !t.find(
                        (y) => y.id === l.id
                      )
                    ).map((l) => /* @__PURE__ */ e.jsxs("li", { className: "mt-4 flex items-center", children: [
                      /* @__PURE__ */ e.jsx(
                        m,
                        {
                          name: "GridAdd",
                          width: 24,
                          strokeWidth: 1.5,
                          className: "p-1 bg-black-10 rounded-full"
                        }
                      ),
                      /* @__PURE__ */ e.jsx(
                        b,
                        {
                          size: "sm",
                          className: "basis-full ml-3 capitalize truncate",
                          children: l.label
                        }
                      ),
                      /* @__PURE__ */ e.jsx(
                        N,
                        {
                          "aria-label": "add",
                          className: "underline-none p-0 h-fit rounded-full",
                          onClick: () => B(l),
                          children: /* @__PURE__ */ e.jsx(m, { name: "Plus", width: 16, strokeWidth: 1.5 })
                        }
                      )
                    ] }, l.id)) })
                  ] }),
                  /* @__PURE__ */ e.jsxs("div", { className: "rounded-md border border-gray-neutral-80 p-3 w-[45%]", children: [
                    /* @__PURE__ */ e.jsxs("div", { className: "flex items-center border-b border-gray-neutral-80 py-2", children: [
                      /* @__PURE__ */ e.jsx(m, { name: "Table2Columns", width: 20, strokeWidth: 1.5 }),
                      /* @__PURE__ */ e.jsx(b, { size: "sm", className: "ml-1", children: "Table View" })
                    ] }),
                    /* @__PURE__ */ e.jsx("ul", { "data-testid": "table-view", children: t.map((l) => /* @__PURE__ */ e.jsxs("li", { className: "mt-4 flex items-center", children: [
                      /* @__PURE__ */ e.jsx(
                        m,
                        {
                          name: "GridAdd",
                          width: 24,
                          strokeWidth: 1.5,
                          className: "p-1 bg-black-10 rounded-full"
                        }
                      ),
                      /* @__PURE__ */ e.jsx(
                        b,
                        {
                          size: "sm",
                          className: "basis-full ml-3 capitalize truncate",
                          children: l.label
                        }
                      ),
                      o.find(
                        (y) => y.id === l.id
                      ) ? null : /* @__PURE__ */ e.jsx(
                        N,
                        {
                          "aria-label": "remove",
                          variant: "link",
                          className: "underline-none p-0 h-0",
                          onClick: () => V(l),
                          children: /* @__PURE__ */ e.jsx(
                            m,
                            {
                              name: "DeleteCircle",
                              width: 16,
                              strokeWidth: 1.5,
                              className: "text-gray-secondary"
                            }
                          )
                        }
                      )
                    ] }, l.id)) })
                  ] })
                ] }),
                j && /* @__PURE__ */ e.jsx(
                  N,
                  {
                    className: "p-2 mt-4 float-right",
                    "aria-label": "reset",
                    size: "sm",
                    onClick: j,
                    children: "Reset"
                  }
                )
              ] })
            ]
          }
        )
      ]
    }
  );
};
G.displayName = "CustomizedTableTrigger";
const q = ({
  children: s,
  className: a,
  data: r,
  headers: d
}) => /* @__PURE__ */ e.jsxs(f, { className: i(a), children: [
  /* @__PURE__ */ e.jsx(f.Header, { children: /* @__PURE__ */ e.jsx(f.Row, { children: d.map((t) => /* @__PURE__ */ e.jsx(f.Head, { className: "capitalize", children: t.label }, t.id)) }) }),
  s,
  !s && r && /* @__PURE__ */ e.jsx(f.Body, { children: r.map((t, o) => /* @__PURE__ */ e.jsx(f.Row, { children: d.map((c) => /* @__PURE__ */ e.jsx(f.Cell, { children: t[c.id] }, `${c}-${o}`)) }, o)) })
] }), Ne = Object.assign(q, { Trigger: G }), he = ({
  data: s,
  columns: a,
  headersLength: r = 10
}) => {
  let d = [];
  s && (d = s.length > 0 ? Object.keys(s[0]).map((p) => ({ id: p, label: p })) : []), a && (d = a);
  const [t, o] = T(d.slice(0, r)), c = (p) => {
    let u = [...t];
    return t.find((n) => (n == null ? void 0 : n.id) && n.id === p.id) ? u = t.filter((n) => (n == null ? void 0 : n.id) && n.id !== p.id) : u = [...t, p], o(u), u;
  }, j = A(
    (p) => {
      const u = p.slice(0, r);
      return o(u), u;
    },
    [o, r]
  );
  return {
    selectedHeaders: t,
    setSelectedHeaders: j,
    allHeaders: d,
    toggleHeader: c
  };
};
export {
  Ne as C,
  fe as L,
  f as T,
  je as U,
  M as a,
  he as u
};
